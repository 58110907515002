import React, { forwardRef } from 'react';
import styled from 'styled-components';
import Title from './Title';

const Section = styled.div`
  height: 100vh;
  overflow-x: hidden;
  background: linear-gradient(to bottom, black, #03161D);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 70%;

  @media (max-width: 700px){
    flex-direction: column;
    justify-content: space-evenly;
  }
`;

const Subtitle = styled.h2`
  font-family: 'Barlow';
  font-size: 23px;
  width: 400px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  @media (max-width: 700px){
    width: 350px;
    font-size: 20px;
  }
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
`;

const RowImg = styled.img`
  width: 80px;
  height: auto;
  color:#0096D8;
`;

const RowText = styled.p`
  font-size: 20px;
  font-family: 'Barlow';
  width: 290px;
  text-align: center;
  color:#0096D8;
  white-space: pre-line;
`;


const Sportsbook = forwardRef((props, ref) => {
  return (
    <Section>
      <Subtitle>SEAMLESS, FULLY CUSTOMIZABLE FRONT-END INTEGRATION INCLUDING:</Subtitle>
      <ContentContainer>
        <RowContainer>
            <RowImg src="/img/iconSport.svg" />
            <RowText>Partial and full cash out across{"\n"}
             all sports and markets</RowText>
        </RowContainer>

        <RowContainer>
            <RowImg src="/img/settings.svg" style={{ width: '65px' }} />
            <RowText>Personalized{"\n"}
             recommendations</RowText>
        </RowContainer>
      </ContentContainer>
    </Section>
  );
});

export default Sportsbook;
