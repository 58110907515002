import React,{useEffect} from 'react';
import styled from 'styled-components';

const TermsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: black;
  min-height: 100%;
  padding-bottom: 100px;
`;

const Title = styled.h1`
  color: #e9e9e9;
  align-items: center;
  text-align:center;
  font-size: 4em;
  font-weight: 100;
  letter-spacing: 5px;
  font-family: 'Barlow';
  white-space: pre-line;
  margin-top: 200px; 

  @media (max-width:650px){
    font-size: 5em;
  }

  @media (max-width:464px){
    font-size: 4em;
  }

  @media (max-width:380px){
    font-size: 3em;
  }
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 55%;

  @media (max-width:1074px){
    width: 600px;
  }

  @media (max-width:650px){
    width: 90%;
  }
`;

const SubTitle = styled.h2`
  color: #FF3B60;
  font-size: 20px;
  margin-bottom: 10px;
  text-align: left; /* Align subtitle to the left */
`;

const Text = styled.p`
  color: white;
  font-size: 18px;
  white-space: pre-line;
  text-align: left; 
  margin-bottom: 20px;
`;

const Terms = () => {


  return (
    <TermsContainer id="terms" >
      <Title>Terms & Conditions</Title>
      
      <TextBox className="term" style={{marginTop:'50px'}}>
        <SubTitle>1. INTRODUCTION</SubTitle>
        <Text>
        Welcome to First.dev. These Terms and Conditions ("Terms") govern your use of our website located at https://first.dev/ ("Website") and the services we provide. By accessing or using our Website, you agree to comply with and be bound by these Terms. If you do not agree to these Terms, please do not use our Website.        </Text>
      </TextBox>

      <TextBox className="term">
        <SubTitle>2. Use of the Website</SubTitle>
        <SubTitle>2.1 Eligibility</SubTitle>
        <Text>
        You must be at least 18 years old to use our Website. By using our Website, you represent and warrant that you are at least 18 years old and that you have the legal capacity to enter into these Terms.</Text>
        <SubTitle>2.2 Prohibited Activities</SubTitle>
        <Text>
        •	Violating any applicable laws or regulations.
        •	Infringing upon or violating our intellectual property rights or the intellectual property rights of others.
        •	Engaging in any activity that could harm or interfere with the functioning of the Website or the services we provide.
        •	Using the Website to distribute spam, viruses, or other harmful software.
        </Text>
      </TextBox>

      <TextBox className="term">
        <SubTitle>3. Intellectual Property</SubTitle>
       
        <Text>
        All content, features, and functionality on the Website, including text, graphics, logos, icons, and images, are the exclusive property of First.dev or its licensors and are protected by intellectual property laws. You may not use any of our intellectual property without our prior written consent.       
        </Text>
      </TextBox>

      <TextBox className="term">
        <SubTitle>4. User Content</SubTitle>
        <SubTitle>4.1 User Submissions</SubTitle>
        <Text>
        You may have the opportunity to post or submit content on our Website. By submitting content, you grant us a non-exclusive, royalty-free, worldwide license to use, reproduce, modify, and distribute your content in connection with our Website and services.
        </Text>

        <SubTitle>4.2 Prohibited Content</SubTitle>
        <Text>
        You agree not to submit any content that:
        •	Is illegal, harmful, or offensive.
        •	Infringes upon the rights of any third party.
        •	Contains viruses or other harmful software.
        </Text>
      </TextBox>

      <TextBox className="term">
        <SubTitle>5. Privacy</SubTitle>
        <Text>
            We are committed to protecting your privacy. Our Privacy Policy, which is incorporated by reference into these Terms, explains how we collect, use, and disclose your personal information. Please review our Privacy Policy here.
        </Text>
      </TextBox>

      <TextBox className="term">
        <SubTitle>6. Disclaimers and Limitation of Liability</SubTitle>
        <SubTitle>6.1 Disclaimers</SubTitle>
        <Text>
        Our Website and services are provided "as is" without warranties of any kind, either express or implied. We do not warrant that the Website will be uninterrupted or error-free.
        </Text>

        <SubTitle>6.2 Limitation of Liability</SubTitle>
        <Text>
        To the fullest extent permitted by law, First.dev and its affiliates, officers, directors, employees, and agents will not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of the Website or these Terms.
                </Text>

        <SubTitle>7. Indemnification</SubTitle>
        <Text>
        You agree to indemnify and hold harmless First.dev and its affiliates, officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses arising out of or in connection with your use of the Website or your violation of these Terms.                </Text>
      </TextBox>

      
      <TextBox className="term">
        <SubTitle>8. Changes to These Terms</SubTitle>
        <Text>
        We reserve the right to modify these Terms at any time. We will provide notice of any changes by posting the new Terms on our Website. Your continued use of the Website after any such changes constitutes your acceptance of the new Terms.    
        </Text>
        </TextBox>

      <TextBox className="term">
        <SubTitle>9. Governing Law</SubTitle>
        <Text>
        These Terms and any disputes arising out of or related to these Terms or the use of the Website will be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law principles.
        </Text>
      </TextBox>

      <TextBox className="term">
        <SubTitle>10. Contact Us</SubTitle>
        <Text>
        If you have any questions or concerns about these Terms, please contact us at contact@first.dev.
        </Text>
      </TextBox>

    </TermsContainer>
  );
};

export default Terms;
