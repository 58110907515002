import React from 'react';
import styled from 'styled-components';


const Container = styled.div`
  width: 100%; 
  height: 520px;
  scroll-snap-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #111517;
  margin: 0;
`;

const First = styled.div`
  width: 100%; /* Ensure the Container takes up the full viewport width */
  height: 50px;
  background-color: #17191C;
`;

const Sec = styled.div`
  width: 100%; /* Ensure the Container takes up the full viewport width */
  height: 50px;
  background-color: #25282C;
`;

const Third = styled.div`
  width: 100%; /* Ensure the Container takes up the full viewport width */
  height: 50px;
  background-color: #40444B;
`;

const Four = styled.div`
  width: 100%; /* Ensure the Container takes up the full viewport width */
  height: 50px;
  background-color: #5F646C;
`;

const Five = styled.div`
  width: 100%; /* Ensure the Container takes up the full viewport width */
  height: 50px;
  background-color: #787e87;
`;


const Six = styled.div`
  width: 100%; /* Ensure the Container takes up the full viewport width */
  height: 50px;
  background-color: #A7AEB7;
`;

const Seven = styled.div`
  width: 100%; /* Ensure the Container takes up the full viewport width */
  height: 50px;
  background-color: #CFD5DD;
`;

const Eight = styled.div`
  width: 100%; /* Ensure the Container takes up the full viewport width */
  height: 50px;
  background-color: #E7ECF2;
`;

const Nine = styled.div`
  width: 100%; /* Ensure the Container takes up the full viewport width */
  height: 120px;
  background-color: #F8FAFC;
`;
const Trading = () => {
    return (
      <Container>
      <Nine/>
      <Eight/>
      <Seven/>
      <Six/>
      <Five/>
      <Four/>
      <Third/>
      <Sec/>
      <First/>
    </Container>
    );
  };
  
  export default Trading;
