import React from 'react';
import styled from 'styled-components';
import Title from './Title';
import video from '../Mobile.mp4';

const Section = styled.div`
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #111417;
`;

const Container = styled.div`
  width: 90%;
  height: 90%;
  scroll-snap-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow: hidden;
  margin-top: 5%;

  @media (max-width: 1000px) {
    flex-direction: column-reverse;
  }
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 5%;
  overflow: hidden;
  
  @media (max-width: 1000px) {
    justify-content: center;
    align-items: center;
    margin-right: 5%;
  }
`;

const Desc = styled.h2`
  width: 14em;
  height: auto;
  font-size: 1.67em;
  letter-spacing: 0.02em;
  text-align: center;
  font-weight: 300;
  font-family: 'Barlow';
  line-height: 1.6;
  margin-left: auto;
  margin-right: 5em;

  @media (max-width: 1000px) {
    width: 14em;
    margin-right: auto;
    margin-left: auto;
    margin-top: -5vh;
  }

  @media (max-width: 420px) {
    width: 14em;
    font-size: 1.2em;
    margin-top: -10vh;
  }
`;

const Right = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 5%;
  overflow: hidden;

  @media (max-width: 1000px) {
    flex: 2;
    justify-content: center;
    align-items: center;
    margin-left: 5%;
  }
`;

const Video = styled.video`
  width: 330px;
  height: auto;
  max-height: 50vh;
  margin-right: auto;
  margin-left: 6em;

  @media (max-width: 1000px) {
    max-height: 50vh;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10vh;
  }
`;

const About = () => {
  return (
    <Section id="works2">
      <Container>
        <Left>
          <Desc>
            Our senior R&D members hold over
            20 years of experience in software development
            for the sports betting industry
          </Desc>
        </Left>
        <Right>
          <Video autoPlay loop muted playsInline={true} controls={false}>
            <source src={video} type="video/mp4" />
          </Video>
        </Right>
      </Container>
    </Section>
  );
};

export default About;