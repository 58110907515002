import React, { useState, useEffect, useRef } from 'react';
import emailjs from '@emailjs/browser';
import styled from 'styled-components';

const Section = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1000px;
  background-color: black;
  position: relative;
  overflow:hidden;

  @media (max-width:550px){
    height: auto;
  }
`;

const Wrap = styled.div`
  width: 75%;
`;

const Container = styled.div`
  position: relative;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 70%;
  height: 100%;

  @media (max-width:1130px){
    width: 100%;
    flex-direction: column;
  }
`;

const LeftSide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  position: relative;

  @media (max-width:1130px){
    display: none;
  }
`;

const Image = styled.img`
  width: 90%;
  height: auto;
  object-fit: cover;
`;

const Title = styled.h1`
  position: absolute;
  font-size: 4vw;
  color: white;

  @media (max-width:1130px){
    font-size: 40px;
  }
`;

const RightSide = styled.div`
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: left;
  align-items: center;

  @media (max-width:1130px){
    width: 100%;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #161B1D;
  width: 100%;
  height: 100%;
  padding: 20px;
`;

const InputCon = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  width: 100%;
`;

const Label = styled.label`
  color: #A7A7A7;
  font-size: 16px;
  font-weight: bold;

  @media (max-width:1441px){
    font-size: 14px;
  }

  @media (max-width:1285px){
    font-size: 12px;
  }

  @media (max-width:1130px){
    font-size: 16px;
  }
`;

const Input = styled.input`
  height: 55px;
  border-radius: 11px;
  margin-top: 5px;
  color: #EDF2F6;
  width: 100%;
  background-color: #434343;
  border: 1px solid transparent;
  box-sizing: border-box;
  outline: none;
  transition: border-color 0.3s ease-in-out;
  font-size: 18px;

  &:focus {
    border: 1px solid #FF3B60;
  }
`;

const TextArea = styled.textarea`
  padding: 10px;
  border-radius: 11px;
  margin-top: 5px;
  color: #EDF2F6;
  background-color: #434343;
  width: 100%;
  border: 1px solid transparent;
  box-sizing: border-box;
  outline: none;
  transition: border-color 0.3s ease-in-out;
  font-size: 18px;

  &:focus {
    border: 1px solid #FF3B60;
  }
`;

const CheckboxLabel = styled.label`
  color: #c9c2c2;
  display: flex;
  align-items: center;
  font-size:16px;

  span {
    color: #FF3B60;
    text-decoration: underline;
    margin-left: 5px;

    @media (max-width:1552px){
      margin-left: 0;
    }
  }

  @media (max-width:1517px){
    font-size:14px;
    }

    @media (max-width:1349px){
    font-size:12px;
    }

    @media (max-width:1181px){
    font-size:10px;
    }

    @media (max-width:1130px){
    font-size:16px;
    }

    @media (max-width:579px){
    font-size:14px;
    }

    @media (max-width:516px){
    font-size:12px;
    }

    @media (max-width:451px){
    font-size:10px;
    }

    @media (max-width:387px){
    font-size:9px;
    }
`;

const TwoColumns = styled.div`
  display: flex;
  margin: 0 auto;
  flex-direction: row;
  width: 100%;

  @media (max-width:550px){
    flex-direction: column;
  }
`;

const HalfWidth = styled.div`
  flex: 1;
  margin: 10px 0;

  @media (max-width:550px){
    width: 100%;
    margin: 0;
  }
`;

const RightHalfWidth = styled(HalfWidth)`
  margin-left: 27px;

  @media (max-width:550px){
    margin-left: 0;
  }
`;

const StyledSelect = styled.div`
  position: relative;
  height: 55px;
  border-radius: 11px;
  margin-top: 5px;
  color: #EDF2F6;
  width: 100%;
  background-color: #434343;
  border: 1px solid ${({ isOpen }) => (isOpen ? '#FF3B60' : 'transparent')}; 
  box-sizing: border-box;
  outline: none;
  cursor: pointer;
  transition: border-color 0.3s ease-in-out;
  display: flex;
  align-items: center;
  padding: 0 10px;

  &:focus {
    border: 1px solid #FF3B60;
  }
`;

const ArrowIcon = styled.img`
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
`;


const Option = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  overflow-y: auto;
  background-color: #505050;
  border-radius: 11px;
  z-index: 1;
  box-shadow: 0px 0px 16px #000000A8;
`;

const OptionItem = styled.div`
  padding: 10px;
  cursor: pointer;
  margin: 5px auto; 
  transition: background-color 0.3s ease;
  width: 80%; /* Adjusted */
  border-radius: 11px;
  color: white;

  &:hover {
    background-color: #ff3b5f66;
  }
`;

const Contact = () => {
  const [isOpenSelect, setIsOpenSelect] = useState({
    name: false,
    type: false,
    expertise: false,
    role: false,
  });

  const selectRefs = {
    name: useRef(null),
    type: useRef(null),
    expertise: useRef(null),
    role: useRef(null),
  };

  const options = [
    { value: '<1 year', label: '<1 year' },
    { value: '1-5 years', label: '1-5 years' },
    { value: '5+ years', label: '5+ years' },
  ];

  const optionsRole = [
    { value: 'Owner/Founder', label: 'Owner/Founder' },
    { value: 'Managment', label: 'Managment' },
    { value: 'Product/IT', label: 'Product/IT' },
    { value: 'Marketing', label: 'Marketing' },
  ];

  const typeCom = [
    { value: 'iGaming Operator', label: 'iGaming Operator' },
    { value: 'iGaming Platform / B2B', label: 'iGaming Platform / B2B' },
    { value: 'Start-up', label: 'Start-up' },
    { value: 'Other', label: 'Other' },
  ];

  const massage = [
    { value: 'Telegram', label: 'Telegram' },
    { value: 'Whatsapp', label: 'Whatsapp' },
    { value: 'Email', label: 'Email' },
    { value: 'Other', label: 'Other' },
  ];

  const handleClickOutside = (event, type) => {
    if (selectRefs[type].current && !selectRefs[type].current.contains(event.target)) {
      setIsOpenSelect((prevState) => ({
        ...prevState,
        [type]: false,
      }));
    }
  };

  const handleSelectClick = (type) => {
    console.log("Clicked:", type);
    setIsOpenSelect((prevState) => ({
      ...Object.fromEntries(Object.entries(prevState).map(([key]) => [key, false])),
      [type]: !prevState[type],
    }));
  };
  
  useEffect(() => {
    console.log("Effect: Setting up event listener");
    const handleClick = (event) => {
      Object.keys(isOpenSelect).forEach((type) => {
        handleClickOutside(event, type);
      });
    };
  
    document.addEventListener('mousedown', handleClick);
  
    return () => {
      console.log("Effect: Removing event listener");
      document.removeEventListener('mousedown', handleClick);
    };
  }, [isOpenSelect]);

  const [selectedOption, setSelectedOption] = useState({
    name: '',
    type: '',
    expertise: '',
    role: '',
  });

  const handleOptionSelect = (type, value) => {
    setSelectedOption((prevSelectedOption) => ({
      ...prevSelectedOption,
      [type]: value,
    }));
  };

  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;


  const [phone,setPhone]=useState('us');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
  
    const email = form.current.email.value; // Get the email input value
    const typeOfCompany = selectedOption.type;
    const expertise = selectedOption.expertise;
    const role = selectedOption.role;
    const preferred = selectedOption.name;
  
    if (!emailRegex.test(email)) {
      alert('Invalid email address');
      return;
    }
  
    const templateParams = {
      name: form.current.name.value,
      phone: form.current.phone.value,
      email: email,
      type: typeOfCompany,
      companyName:form.current.companyName.value,
      expertise:expertise,
      role:role,
      preferred:preferred,
      messengerId:form.current.messengerId.value,
      message:form.current.message.value,
    };
  
    emailjs
      .send('service_pwmp7lc', 'template_rhageik', templateParams, 'EoqW1rPYvLYanhbNg')
      .then((result) => {
        console.log(result.text);
        console.log(typeOfCompany); // You can access typeOfCompany here
        setFormSubmitted(true);
        form.current.reset();
      })
      .catch((error) => {
        console.error('EmailJS Error:', error);
        // Handle the error, e.g., show an error message to the user
      });
  };
  
  

  return (
    <Section id="contact">
      <Container>
      {window.innerWidth > 1130? (
        <LeftSide>
          <Image src="/img/coach.png" alt="Background" />
          <Title>CONTACT US</Title>
        </LeftSide>
      ):
      (
        <Title style={{position:'relative'}}>CONTACT US</Title>
      )}
       
        <RightSide>
          <Form ref={form} onSubmit={sendEmail}>
            <Wrap>
              <InputCon>
                <Label>YOUR FULL NAME <span style={{ color: '#FF3B60' }}>*</span></Label>
                <Input type="text" name="name" placeholder="ex. John Doe" required />
              </InputCon>

              <TwoColumns>
                <HalfWidth>
                  <InputCon>
                    <Label>PHONE<span style={{ color: '#FF3B60' }}>*</span></Label>
                    <Input type="phone" name="phone" required />
                  </InputCon>
                </HalfWidth>
                <RightHalfWidth>
                  <InputCon>
                    <Label>Email<span style={{ color: '#FF3B60' }}>*</span></Label>
                    <Input type="email" name="email" placeholder="ex. doe@gmail.com" required />
                  </InputCon>
                </RightHalfWidth>
              </TwoColumns>

              <TwoColumns>
                <HalfWidth>
                  <InputCon>
                    <Label>TYPE OF COMPANY<span style={{ color: '#FF3B60' }}>*</span></Label>
                    <StyledSelect isOpen={isOpenSelect.type} onClick={() => handleSelectClick('type')} ref={selectRefs.type}>
                      <span>{selectedOption.type || 'Select type...'}</span>
                      <Option isOpen={isOpenSelect.type}>
                        {typeCom.map((typeCom) => (
                          <OptionItem 
                            key={typeCom.value}
                            value={typeCom.value}
                            onClick={() => handleOptionSelect('type', typeCom.value)}
                          >
                            {typeCom.label}
                          </OptionItem>
                        ))}
                      </Option>

                      <ArrowIcon src="/img/arrow.svg" alt="" />
                    </StyledSelect>
                  </InputCon>
                </HalfWidth>

                <RightHalfWidth>
                  <InputCon>
                    <Label>COMPANY NAME<span style={{ color: '#FF3B60' }}>*</span></Label>
                    <Input type="text" name="companyName" placeholder="ex." required />
                  </InputCon>
                </RightHalfWidth>
              </TwoColumns>

              <TwoColumns>
                <HalfWidth>
                  <InputCon name="expertise">
                    <Label>INDUSTRY EXPERTISE<span style={{ color: '#FF3B60' }}>*</span></Label>
                    <StyledSelect isOpen={isOpenSelect.expertise} onClick={() => handleSelectClick('expertise')} ref={selectRefs.expertise}>
                      <span>{selectedOption.expertise || 'Select expertise...'}</span>
                      <Option isOpen={isOpenSelect.expertise}>
                        {options.map((option) => (
                          <OptionItem
                            key={option.value}
                            value={option.value}
                            onClick={() => handleOptionSelect('expertise', option.value)}
                          >
                            {option.label}
                          </OptionItem>
                        ))}
                      </Option>
                      <ArrowIcon src="/img/arrow.svg" alt="" />
                    </StyledSelect>
                  </InputCon>
                </HalfWidth>
                <RightHalfWidth>
                  <InputCon name="role">
                    <Label>CURRENT ROLE<span style={{ color: '#FF3B60' }}>*</span></Label>
                    <StyledSelect isOpen={isOpenSelect.role} onClick={() => handleSelectClick('role')} ref={selectRefs.role}>
                      <span>{selectedOption.role || 'Select role...'}</span>
                      <Option isOpen={isOpenSelect.role}>
                        {optionsRole.map((optionsRole) => (
                          <OptionItem
                            key={optionsRole.value}
                            value={optionsRole.value}
                            onClick={() => handleOptionSelect('role', optionsRole.value)}
                          >
                            {optionsRole.label}
                          </OptionItem>
                        ))}
                      </Option>
                      <ArrowIcon src="/img/arrow.svg" alt="" />
                    </StyledSelect>
                  </InputCon>
                </RightHalfWidth>
              </TwoColumns>

              <TwoColumns>
                <HalfWidth>
                  <InputCon name="preferred">
                    <Label>PREFERRED MESSENGER</Label>
                    <StyledSelect isOpen={isOpenSelect.name} onClick={() => handleSelectClick('name')} ref={selectRefs.name}>
                      <span>{selectedOption.name || 'Select messenger...'}</span>
                      <Option isOpen={isOpenSelect.name}>
                        {massage.map((massage) => (
                          <OptionItem
                            key={massage.value}
                            value={massage.value}
                            onClick={() => handleOptionSelect('name', massage.value)}
                          >
                            {massage.label}
                          </OptionItem>
                        ))}
                      </Option>
                      <ArrowIcon src="/img/arrow.svg" alt="" />
                    </StyledSelect>
                  </InputCon>
                </HalfWidth>
                <RightHalfWidth>
                  <InputCon>
                    <Label>MESSENGER ID</Label>
                    <Input type="text" name="messengerId" placeholder="ex. @doe" />
                  </InputCon>
                </RightHalfWidth>
              </TwoColumns>

              <InputCon>
                <Label>MESSAGE<span style={{ color: '#FF3B60' }}>*</span></Label>
                <TextArea
                  type="text"
                  name="message"
                  placeholder="Your Message"
                  rows="4"
                  required
                />
              </InputCon>

              <InputCon>
                <CheckboxLabel>
                  <input type="checkbox" name="agree" required/>
                  <span style={{color:'#FF3B60',textDecoration:'none'}}>*</span> By checking this box, I agree to the FIRST{' '}
                  <span>Privacy Policy</span>
                </CheckboxLabel>
              </InputCon>
            </Wrap>
            <hr style={{ width: '75%' }} />
            <button style={{ width: '75%',background:'#009de68c',height:'70px' ,color:'white',borderRadius:'11px',border:'none'}} type="submit">SEND</button>
          </Form>
        </RightSide>
      </Container>
    </Section>
  );
};

export default Contact;
