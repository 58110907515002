// import React from 'react';
// import styled from 'styled-components';
// import { Link } from 'react-router-dom';

// const FooterContainer = styled.footer`
//   background-color: #333;
//   color: white;
//   height: 80px;
//   display: flex;
//   justify-content: center;
//   position: relative;
//   overflow-x:hidden;
//   background-color: #0d2028;
//   display: flex;
//   flex-direction: row;

//   @media (max-width: 768px) {
//     height: 180px;
//     align-items: center;
//   }
// `;

// const Logo = styled.img`
//   width: 140px; 
//   height: auto; 
//   left:20px;
//   position: absolute;
//   top: 25px;

//   @media (max-width: 768px) {
//     width: 160px; 
//     top: 2px;
//   }
// `;

// const LogoContainer = styled.div`
//   flex: 1;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   text-align: center;
//   font-family: 'Barlow', sans-serif;
//   font-size: 20px;
//   width: 100%;
//   position: absolute;
//   z-index: 888;
//   background-color: #594a4a;

//   @media (max-width: 768px) {
//     width: 200px;
//     margin-left: auto;
//     margin-right: auto;
//     background-color: #0f074b;
//   }
// `;


// const TextContainer = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   text-align: center;
//   font-family: 'Barlow', sans-serif;
//   font-size:90%;
//   width: 70%;
//   background-color: red;
//   cursor:pointer;
  
//   /* @media (max-width: 768px) {
//     width: 40%;
//     font-size: 22px;
//     order: 2;
//     margin-top: -60px;
//     margin-left: auto;
//     margin-right: auto;
//   } */

//   span{

//     &:hover{
//         text-decoration: underline;
//       }
//     a{
//       text-decoration: none;
//     }
//   }
// `;


// const Footer = () => {
//   return (
//     <FooterContainer>
//     <LogoContainer>
//        <Logo src="/img/First.svg" alt="Company Logo" />
//     </LogoContainer>
     

   
//       <TextContainer>
//       <span>Inquiry@first.dev</span>  &nbsp;&nbsp; | &nbsp;&nbsp; <span><Link style={{color:'white',}} to="/terms">Terms & Conditions</Link></span>  &nbsp;&nbsp; | &nbsp;&nbsp; <span><Link style={{color:'white',}} to="/policy">Privacy Policy</Link></span>&nbsp;&nbsp; | &nbsp;&nbsp;<span>Gamma Sports Group</span>  &nbsp;&nbsp; | &nbsp;&nbsp; <span>Address:str. Filip</span>  &nbsp;&nbsp; | &nbsp;&nbsp; <span>Kutev,Sofia Bulgaria</span>  &nbsp;&nbsp; | &nbsp;&nbsp; <span>UIC:205466228</span>  &nbsp;&nbsp;
//       </TextContainer>
     
      
//     </FooterContainer>
//   );
// };

// export default Footer;

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const FooterContainer = styled.footer`
  background-color: #0d2028;
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
`;

const Logo = styled.img`
  width: 140px;
  height: auto;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    width: 120px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-family: 'Barlow', sans-serif;
  font-size: 16px;
  text-align: center;

  @media (max-width: 1200px) {
    font-size: 14px;
  }

  @media (max-width: 992px) {
    font-size: 12px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    font-size: 14px;
  }
`;

const TextItem = styled.span`
  margin: 5px 10px;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }

  a {
    color: white;
    text-decoration: none;
  }

  @media (max-width: 768px) {
    margin: 5px 0;
  }
`;

const Divider = styled.span`
  @media (max-width: 768px) {
    display: none;
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <Logo src="/img/First.svg" alt="Company Logo" />
      <TextContainer>
        <TextItem>Inquiry@first.dev</TextItem>
        <Divider>|</Divider>
        <TextItem><Link to="/terms">Terms & Conditions</Link></TextItem>
        <Divider>|</Divider>
        <TextItem><Link to="/policy">Privacy Policy</Link></TextItem>
        <Divider>|</Divider>
        <TextItem>Gamma Sports Group</TextItem>
        <Divider>|</Divider>
        <TextItem>Address: str. Filip</TextItem>
        <Divider>|</Divider>
        <TextItem>Kutev, Sofia Bulgaria</TextItem>
        <Divider>|</Divider>
        <TextItem>UIC:205466228</TextItem>
      </TextContainer>
    </FooterContainer>
  );
};

export default Footer;