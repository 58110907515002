import React from 'react';
import styled from 'styled-components';
import Title from './Title';

const Section = styled.div`
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
`;

const ImgContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 90%;
  height: 80%;
  margin-top: 20px;

  @media (max-width: 1600px) {
    height: 70%;
  }

  @media (max-width: 1300px) {
    margin-right: auto;
    margin-left: auto;
    justify-content: flex-end;
  }

  @media (max-width: 800px) {
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
  }
`;

const Img = styled.img`
  width: 1400px;
  height: auto;
  object-position: right;

  @media (max-width: 800px) {
    display:none;
  }

`;

const ImgMobile = styled.img`
   width: 1200px;
  height: auto;
  display:none;

  @media (max-width: 800px) {
    max-width: 550px;
    margin-left: 20px;
    object-position: center;
    display:block;
  }
`;

const Offices = () => {
  return (
    <Section>
      <Title content="OFFICES" />
      <ImgContainer>
        <Img src="/img/map.svg" />
        <ImgMobile src="/img/mapM.svg" />
      </ImgContainer>
    </Section>
  );
};

export default Offices;
