import React, { useRef } from 'react';
import styled from 'styled-components';
import Title from './Title';
import video from '../herov.mp4';

const Section = styled.div`
  height: 100vh;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #000000;
`;

const Container = styled.div`
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* overflow: hidden; */
  /* background: blue; */

  @media (max-width: 1100px) {
    flex-direction: column-reverse; /* Stack content in a column for screens at most 768px wide */
  }
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* background: purple; */
  margin-left: 5%;
  /* overflow: hidden; */

 

  @media (max-width: 1100px) {
    justify-content: center; 
    align-items: center;
    margin-right: 5%;
  }

`;


const Right = styled.div`
  flex: 1.3;
  display: flex;
  justify-content: flex-start; 
  align-items: center;
  /* background: orange; */
  margin-right: 5%;
  margin-left: 5%;
  /* overflow: hidden; */

  /* @media (max-width: 1800px) {
    background: green;
  }

  @media (max-width: 1700px) {
    background: blue;
  }

  @media (max-width: 1500px) {
    background: pink;
  }

  @media (max-width: 1300px) {
    background: #701625;
  }

  @media (max-width: 1250px) {
    background: #b249ad;
  } */


  @media (max-width: 1100px) {
    flex: 1.5;
    justify-content: center; 
    align-items: center;
  }

`;


const Video = styled.video`
  width: 60%;
  height: auto;
  max-height: 100vh;
  margin-right: auto;
  margin-left:auto;

  @media (max-width: 500px) {
    width: 100%;
    margin-top: 30%;
  }

`;

const Img = styled.img`
  width: 50%;
  height: auto;
  max-height: 100vh;
  margin-left: auto;
  margin-right: 8em;

  @media (max-width: 1800px) {
    margin-right: 6em;
  }

  @media (max-width: 1700px) {
    margin-right: 5em;
  }

  @media (max-width: 1500px) {
    margin-right: 2em;
  }

  @media (max-width: 1300px) {
    max-height: 13vh; 
    margin-right: 2em;
  }

  @media (max-width: 1250px) {
    max-height: 13vh; 
    margin-right: 2em;
  }

  @media (max-width: 1100px) {
    width: 280px;
    margin-right: auto;
    margin-left: auto;
    margin-top: -80px;
  }

  @media (max-width: 500px) {
    width: 70%;
    margin-top: -100px;
    margin-right: auto;
    margin-left: auto;
  }

  @media (max-width: 414px) {
    margin-top: -30px;
  }

  @media (max-width: 390px) {
    margin-top: -50px;
  }
  `;

const Hero = () => {


  return (
    <Section id="home" >
      <Container>
        <Left>
          <Img src="/img/logoHero.svg"/>
        </Left>

        <Right>
          <Video autoPlay loop muted playsInline controls={false}>
            <source src={video} type="video/mp4" />
          </Video>
        </Right>
      </Container>
    </Section>
  );
};

export default Hero;