import React from 'react';
import styled from 'styled-components';

const Section = styled.div`
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row; 
  background-color: #000000;
  scroll-margin-top: 40px;
  
  @media (max-width: 880px) {
    background-color: #081c24;
    scroll-margin-top:0;
  }

`;

const Container = styled.div`
  width: 100%; 
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #081c24;
  

  @media (max-width: 880px) {
    flex-direction: column;
    margin-top: auto;
    margin-bottom: auto;
  }
`;

const Side = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1; /* Equal width for both sides */
  height: 100%; /* Take up the full height of the Section */
  margin: 0 1%; 
  /* background-color: #845c62; */

  @media (max-width: 880px) {
    flex-direction: column;
    margin: 0;
  }

`;

const Img = styled.img`
    width: 100px;
    /* background-color: purple; */


  @media (max-width: 880px) {
    margin-left: auto;
    margin-right: auto;
    margin-bottom:15px;
  }
`;

const Text = styled.div`
  font-weight: bold;
  width: 150px;
  font-size: 23px;
  margin-left: 6.5%;
  color: ${(props) => (props.dark ? '#1D91E0' : '#03C7F9')};
  font-family: 'Barlow';
  transform: scaleX(1.04);
  line-height: 1.4;
  /* background-color: #562727; */

  @media (min-width: 1920px) {
    width: 150px;
    text-align: left;
    font-size: 23px;
  }

  @media (max-width: 880px) {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  
  @media (max-width: 440px) {
    font-size: 20px;
  }
`;

const Blue = () => {

  const value =true;
  
  return (
    <Section>
      <Container>
        <Side>
          <Img src="/img/people.svg" />
          <Text>OVER 300 EMPLOYEES</Text>
        </Side>
        <Side>
          <Img src="/img/innovation.svg" />
          <Text style={{width:'300px'}} dark={value.toString()}>MORE THAN HALF ARE R&D, DELIVERY, INNOVATION</Text>
        </Side>
      </Container>
    </Section>
  );
};

export default Blue;