import React, { useRef, useEffect, useState,forwardRef  } from 'react';
import styled from 'styled-components';
import Title from './Title';
import video from '../pl.mp4';

const Section = styled.div`
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #111417;
`;

const Container = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow: hidden;
  /* background: red; */
  

  @media (max-width: 1000px) {
    flex-direction: column-reverse; /* Stack content in a column for screens at most 768px wide */
  }

  @media (max-width: 420px) {
    height: 70vh; 
    flex-direction: column-reverse; 
    margin-left: auto;
    margin-right: auto;
  }
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* background: #b240b2; */
  margin-left: 5%;
  overflow: hidden;
 
 

  @media (max-width: 1000px) {
    justify-content: center; 
    align-items: center;
    margin-right: 5%;
  }
`;

const Desc = styled.h2`
  width: 13em;
  height: auto;
  font-size: 1.67em;
  letter-spacing: 0.02em;
  text-align: center;
  font-weight: 300;
  font-family: 'Barlow';
  line-height: 1.6;
  margin-left: auto;
  margin-right: 5em;


  @media (max-width: 1380px) {
    width: 13em;
    margin-right: auto;
    margin-left: auto;
    /* background: green; */
  }

  @media (max-width: 1200px) {
    width: 13em;
    margin-right: auto;
    margin-left: auto;
    /* background: blue; */
  }

  @media (max-width: 1000px) {
    width: 13em;
    margin-right: auto; 
    margin-left: auto;
    margin-top: -10vh;
    /* background: pink; */
  }

  @media (max-width: 480px) {
    width: 13em;
    font-size: 1.3em;
    margin-right: auto; 
    margin-left: auto;
    margin-top: -10vh;
    /* background: #3e3e09; */
  }

  @media (max-width: 420px) {
    width: 13em;
    font-size: 1.3em;
    margin-right: auto; 
    margin-left: auto;
    top: 0;
    /* background: green; */
  }

  @media (max-width: 390px) {
    width: 13em;
    font-size: 1.3em;
    margin-right: auto; 
    margin-left: auto;
    top: 0;
    /* background: #747410; */
  }
`;

const Right = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start; /* Horizontally align content to the left */
  align-items: center;
  /* background: orange; */
  margin-right: 5%;
  overflow: hidden;



  @media (max-width: 1000px) {
    flex: 2;
    justify-content: center; /* Center content when stacked */
    align-items: center;
    margin-left: 5%;
  }
`;


const Video = styled.video`
  width: 330px;
  height: auto;
  max-height: 50vh;
  margin-right: auto;
  margin-left: 8em;

  @media (max-width: 1380px) {
    max-height: 50vh; 
    margin-right: auto;
    margin-left: auto;
  }

  @media (max-width: 1200px) {
    max-height: 50vh; 
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 1000px) {
    max-height: 50vh; 
    margin-left: auto;
    margin-right: auto;
    margin-top: 5vh;
  }

  @media (max-width: 420px) {
    height: 35vh; 
    margin-left: auto;
    margin-right: auto;
    margin-top: 5vh;
  }
`;


const Who = forwardRef((props, ref) => {


  return (
    <Section id="about-us">
      <Title content="ABOUT US" />

      <Container>
        <Left>
        <Desc>
            Our clients include the biggest sponsors of EPL
          </Desc>

        </Left>

        <Right>
          <Video autoPlay loop muted playsInline={true} controls={false}>
            <source src={video} type="video/mp4" />
          </Video>
        </Right>
      </Container>
    </Section>
  );
});

export default Who;