import React, { useState ,useEffect} from 'react';
import styled from 'styled-components';
import { css } from 'styled-components';
import Title from './Title';

const WhiteContainer = styled.div`
  background-color: #ffffff;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
`;

const CarouselImage = styled.img`
  width: 300px; 
  height: 550px; 
  position: relative;
  display: ${props => (props.visible ? 'block' : 'none')};

  @media (max-width: 500px){
      height: 550px; 
  }
`;

const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    overflow-y:hidden;
    height: 650px;
    /* background: red; */

    @media (max-width: 1600px){
      height: 500px;
      margin-top: 10px;
    }

    @media (max-width: 1180px){
      margin-top: 0px;
    }
`;

const Image = styled.img`
  width: ${({ mobileWidth }) => mobileWidth || '1200px'};
  height: ${({ mobileHeight }) => mobileHeight || '650px'};
  transition: transform 0.5s ease;

  @media (max-width: 1600px) {
    padding-top: 10px;
    width: ${({ mobileWidth }) => mobileWidth || '1000px'};
    height: ${({ mobileHeight }) => mobileHeight || '480px'};
  }
`;


const ButtonContainer = styled.div`
  display: flex;
`;

const Button = styled.button`
  width: 231px;
  height: 40px;
  border: 2px solid ${({ isActive }) => (isActive ? '#000000' : '#7f7c7c')};
  background: white;
  color: ${({ isActive }) => (isActive ? '#000000' : '#7f7c7c')};
  margin: 0 18px;
  cursor: pointer;
`;

const RoundIndicatorsContainer = styled.div`
  display: flex;
  margin-top: 20px;
`;

const RoundIndicator = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${props => (props.active ? '#3e3f3f' : '#7c7d7d88')};
  margin: 0 8px;
  cursor: pointer;
`;

function White() {
    const [currentImages, setCurrentImages] = useState(['/img/Laptoplight.png']);
    const [mobileWidth, setMobileWidth] = useState(null);
    const [mobileHeight, setMobileHeight] = useState(null);
    const [activeButton, setActiveButton] = useState('/img/Laptoplight.png');
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [touchStartX, setTouchStartX] = useState(null);
    const images = [
      '/img/mobleft.png',
      '/img/mid.png',
      '/img/mobright.png',
      '/img/darkleft.png',
      '/img/middark.png',
      '/img/darkright.png',
    ];
    
  

    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    
  
    const handleButtonClick = (newImages) => {
      if (Array.isArray(newImages)) {
        setCurrentImages(newImages);
    
        // Set width and height for mobile views
        if (newImages.length > 0) {
          if(windowWidth <=1600){
            setMobileWidth('280px');
            setMobileHeight('450px');
          }else{
            setMobileWidth('300px');
            setMobileHeight('550px');
          }
        }
       
         else {
          setMobileWidth(null);
          setMobileHeight(null);
        }
    
        // Set active button
        setActiveButton(newImages[0] || '/img/Laptoplight.png');
      } else {
        // If newImages is not an array, assume it's a single image
        setCurrentImages([newImages]);
    
        // Set width and height for mobile views
        setMobileWidth(null);
        setMobileHeight(null);
    
        // Set active button
        setActiveButton(newImages);
      }
    };

    const handleIndicatorClick = index => {
      setCurrentIndex(index);
    };

    const handleImageButtonClick = newIndex => {
      setCurrentIndex(newIndex);
    };

    const handleSwipe = (direction) => {
      if (direction === 'left') {
        setCurrentIndex((prev) => (prev + 1) % AllImages.length);
      } else if (direction === 'right') {
        setCurrentIndex(
          (prev) => (prev - 1 + AllImages.length) % AllImages.length
        );
      }
    };

    const handleTouchStart = (event) => {
      setTouchStartX(event.touches[0].clientX);
    };

    const handleTouchMove = (event) => {
      if (touchStartX !== null) {
        const deltaX = event.touches[0].clientX - touchStartX;
        const sensitivity = 50;
  
        if (deltaX > sensitivity) {
          // Swipe right
          handleImageButtonClick((currentIndex - 1 + images.length) % images.length);
          setTouchStartX(null);
        } else if (deltaX < -sensitivity) {
          // Swipe left
          handleImageButtonClick((currentIndex + 1) % images.length);
          setTouchStartX(null);
        }
      }
    };

    const prevIndex = (currentIndex - 1 + images.length) % images.length;
    const nextIndex = (currentIndex + 1) % images.length;
  
    const translateXValue = `-${currentIndex * 0}%`;

  return (
    <WhiteContainer id="sports">
      <Title content="SPORTSBOOK" isSport={true} />
      {window.innerWidth <= 1180 ? (
        <>
        <ImageContainer>
        {images.map((image, index) => (
          <CarouselImage
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            style={{ transform: `translateX(${translateXValue})` }}
            key={index}
            src={image}
            alt={`Image ${index + 1}`}
            visible={currentIndex === index}
            style={{height:'450px',width:'250px'}}
          />
        ))}
        </ImageContainer>

        <RoundIndicatorsContainer>
          {images.map((_, index) => (
            <RoundIndicator key={index} active={currentIndex === index} onClick={() => handleIndicatorClick(index)}/>
          ))}
        </RoundIndicatorsContainer>
        </>
      ) : (

        <>
        <ImageContainer>
          {currentImages.map((image, index) => (
            <Image
              id={currentImages.length === 1 ? `single-image` : `image${index}`}
              key={index}
              src={image}
              alt={`Description ${index}`}
              mobileWidth={mobileWidth}
              mobileHeight={mobileHeight}
              style={
                window.innerWidth > 1600
                  ? {
                      ...currentImages.length !== 1 && (index === 0 || index === 2)
                        ? { width: '280px', height: '480px' }
                        : {},
                    }
                  : {
                      ...currentImages.length !== 1 && (index === 0 || index === 2)
                        ? { width: '250px', height: '400px' }
                        : {},
                    }
              }
            />
          ))}
        </ImageContainer>

      <ButtonContainer>
        <Button
          onClick={() => handleButtonClick('/img/Laptoplight.png')}
          isActive={activeButton === '/img/Laptoplight.png'}
        >
          Desktop Light View
        </Button>
        <Button
          onClick={() => handleButtonClick('/img/Laptopdark.png')}
          isActive={activeButton === '/img/Laptopdark.png'}
        >
          Desktop Dark View
        </Button>
        <Button
          onClick={() => handleButtonClick(['/img/mobleft.png','/img/mid.png','/img/mobright.png'])}
          isActive={activeButton === '/img/mobleft.png'}
        >
          Mobile Light View
        </Button>
        <Button
          onClick={() => handleButtonClick(['/img/darkleft.png','/img/middark.png','/img/darkright.png'])}
          isActive={activeButton === '/img/darkleft.png'}
        >
          Mobile Dark View
        </Button>
      </ButtonContainer>
      </>
      )}
    </WhiteContainer>
  );
}

export default White;
