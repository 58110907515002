import React ,{forwardRef} from 'react';
import styled from 'styled-components';
import Title from './Title';
import video from '../Teams.webm';


const Section = styled.div`
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: hidden; /* Add this line to hide the vertical scrollbar */
  background-color: #000000;

  @media (max-width: 1000px) {
    padding-bottom: 300px;
  }

  @media (max-width: 390px) {
    height:auto;
    padding-bottom: 0px;
  }

`;


const Container = styled.div`
  width: 90%;
  height: 80%;
  display: flex;
  gap: 55px; 
  grid-template-columns: 1fr 1fr;
  align-items: center;
  /* background-color: #1d34ca; */
  position: relative;
  flex-direction: row;
  /* overflow:hidden; */

  @media (max-width:1600px){
    height: 70%;
    margin-top: 2%;
  }

  @media (max-width:1000px){
    flex-direction: column;
    gap:0;
    height: 150%;
  }

  @media (max-width: 390px) {
    height:auto;
  }
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-end; 
  align-items: center;
  flex-direction: column;
  /* background-color: #008000; */
  /* overflow:hidden; */

  
  @media (max-width:1000px){
     padding:50px 0;
  }
`;

const Right = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-end; 
  align-items: center;
  flex-direction: column;
  /* background-color: #8d6068; */
  /* overflow:hidden; */
  
  @media (max-width:1000px){
     padding:50px 0;
  }
`;


const DescL = styled.h2`
  height: auto;
  font-size: 20px;
  text-align:center;
  font-weight:300;
  font-family: 'Helvetica';
  margin: 0;
  line-height: 1.5; 
  margin-top: -1.5%;
  margin-right: auto;
  margin-left: auto;
  white-space:pre-line;

  @media (max-width:1600px){
    font-size: 3vh;
    width: 500px;
  }

  @media (max-width:1440px){
    font-size: 2.5vh;
  }

  @media (max-width:1200px){
    font-size: 1.2em;
    width: 400px;
  }

  @media (max-width:1100px){
      font-size: 1.1em;
      width: 350px;
  }

  @media (max-width:450px){
    font-size: 1em;
      width: 300px;
  }
`;

const DescR = styled.h2`
  height: auto;
  font-size: 20px;
  text-align:center;
  font-weight:300;
  font-family: 'Helvetica';
  margin: 0;
  line-height: 1.5; 
  margin-top: -1.5%;
  margin-right: auto;
  margin-left: auto;
  white-space:pre-line;

  @media (max-width:1600px){
    font-size: 3vh;
    width: 380px;
  }

  @media (max-width:1440px){
    font-size: 2.5vh;
  }

  @media (max-width:1200px){
    font-size: 1.2em;
    width: 400px;
  }

  @media (max-width:1100px){
      font-size: 1.1em;
      width: 300px;
  }
  
  @media (max-width:450px){
    font-size: 1em;
      width: 300px;
  }
`;

const Img = styled.img`
    width: 40%; 
    height:40%;
    margin-left: auto; 
    margin-right: auto;

    @media (max-width:440px){
      height:50%;
  }
`;

const Mac = styled.img`
    width: 50%; 
    height:60%;
`;

const SubtitleL = styled.h1`
    font-size: 30px;
    letter-spacing: 3px;
    font-family: 'Barlow';
    font-weight: 700;
    color: #ffffff;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    white-space: pre-line;
   

    @media (max-width:1000px){
      font-size: 1.8em;
  }

  @media (max-width:900px){
    font-size: 1.3em;
    width: 250px;
  }

  @media (max-width:500px){
    width:260px; 
  }
  
`;

const SubtitleR = styled.h1`
    font-size: 30px;
    letter-spacing: 3px;
    font-family: 'Barlow';
    font-weight: 700;
    color: #ffffff;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    white-space: pre-line;

  @media (max-width:1000px){
      font-size: 1.8em;
      width: 330px;
      margin-top:15px;
  }

  @media (max-width:900px){
    font-size: 1.3em;
    width: 250px;
  }

  @media (max-width:500px){
    width: 230px;
  }

`;



const Trading =  forwardRef((props, ref) => {

  
  
  return (
      <Section id="trading">
      <Title content="TRADING" />
 

      <Container>
        <Left>
            <Mac src="/img/mac.svg"/>
            <SubtitleL>WORKING IN THE {"\n"} 
             TOUGHEST MARKETS {"\n"} </SubtitleL>
            <DescL>Our traders navigate the most challenging {"\n"} 
            global markets, serving the world's most{"\n"} 
             profitable and sophisticated clients{"\n"}  </DescL>
        </Left>

        <Right>
            <Img src="/img/group.svg"/>
            <SubtitleR>HIGH PAYOUTS IN  {"\n"} 
            HIGH-RISK MARKETS {"\n"} </SubtitleR>
            <DescR>Our expertise in high-risk markets enables{"\n"}  
            us to offer high payouts with a small{"\n"}  
            theoretical margin{"\n"} </DescR>
        </Right>
      </Container>
    </Section> 
  );
});


export default Trading;