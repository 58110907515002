import React,{useEffect} from 'react';
import styled from 'styled-components';

const TermsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: black;
  min-height: 100%;
  padding-bottom: 100px;
`;

const Title = styled.h1`
  color: #e9e9e9;
  align-items: center;
  text-align:center;
  font-size: 4em;
  font-weight: 100;
  letter-spacing: 5px;
  font-family: 'Barlow';
  white-space: pre-line;
  margin-top: 200px; 

  @media (max-width:650px){
    font-size: 5em;
  }

  @media (max-width:464px){
    font-size: 4em;
  }

  @media (max-width:380px){
    font-size: 3em;
  }
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 55%;

  @media (max-width:1074px){
    width: 600px;
  }

  @media (max-width:650px){
    width: 90%;
  }
`;

const SubTitle = styled.h2`
  color: #FF3B60;
  font-size: 20px;
  margin-bottom: 10px;
  text-align: left; /* Align subtitle to the left */
`;

const Text = styled.p`
  color: white;
  font-size: 18px;
  white-space: pre-line;
  text-align: left; 
  margin-bottom: 20px;
`;

const Policy = () => {


  return (
    <TermsContainer id="policy">
      <Title>Private Policy</Title>
      
      <TextBox className="term" style={{marginTop:'50px'}}>
        <SubTitle>1. INTRODUCTION</SubTitle>
        <Text>
        Welcome to First.dev. These Terms and Conditions ("Terms") govern your use of our website located at https://first.dev/ ("Website") and the services we provide. By accessing or using our Website, you agree to comply with and be bound by these Terms. If you do not agree to these Terms, please do not use our Website.        </Text>
      </TextBox>

      <TextBox className="term">
        <SubTitle>2. Information We Collect</SubTitle>
        <SubTitle>2.1 Personal Information</SubTitle>
        <Text>
        We may collect personal information that you voluntarily provide to us when you use our Website, including but not limited to:
        •	Name
        •	Email address
        •	Phone number
        •	Address
        </Text>
        <SubTitle>2.2 Usage Data</SubTitle>
        <Text>
        We may also collect information about how you access and use the Website ("Usage Data"). This Usage Data may include information such as your IP address, browser type, browser version, the pages you visit, the time and date of your visit, the time spent on those pages, and other diagnostic data.
        </Text>
      </TextBox>

      <TextBox className="term">
        <SubTitle>2.3 Cookies and Tracking Technologies</SubTitle>
       
        <Text>
        We use cookies and similar tracking technologies to track the activity on our Website and hold certain information. Cookies are files with a small amount of data which may include an anonymous unique identifier. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Website.
                </Text>
      </TextBox>

      <TextBox className="term">
        <SubTitle>3. How We Use Your Information</SubTitle>
        <Text>
        We use the information we collect in various ways, including to:
        •	Provide, operate, and maintain our Website
        •	Improve, personalize, and expand our Website
        •	Understand and analyze how you use our Website
        •	Develop new products, services, features, and functionality
        •	Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the Website, and for marketing and promotional purposes
        •	Process your transactions and manage your orders
        •	Send you emails
        •	Find and prevent fraud
        </Text>

        <SubTitle>4. Sharing Your Information</SubTitle>
        <Text>
        We may share your personal information in the following situations:
        •	With Service Providers: We may share your personal information with third-party service providers to perform services on our behalf, such as payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.
        •	For Business Transfers: We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
        •	With Affiliates: We may share your information with our affiliates, in which case we will require those affiliates to honor this Privacy Policy.
        •	With Business Partners: We may share your information with our business partners to offer you certain products, services, or promotions.
        •	With Your Consent: We may disclose your personal information for any other purpose with your consent.
        •	As Required by Law: We may disclose your personal information if required to do so by law or in response to valid requests by public authorities (e.g., a court or a government agency).

        </Text>
      </TextBox>

      <TextBox className="term">
        <SubTitle>5. Security of Your Information</SubTitle>
        <Text>
        We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.        </Text>
      </TextBox>

      <TextBox className="term">
        <SubTitle>6. Your Data Protection Rights</SubTitle>
        <Text>
        Depending on your location, you may have the following rights regarding your personal information:
        •	The right to access – You have the right to request copies of your personal data.
        •	The right to rectification – You have the right to request that we correct any information you believe is inaccurate or complete information you believe is incomplete.
        •	The right to erasure – You have the right to request that we erase your personal data, under certain conditions.
        •	The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.
        •	The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.
        •	The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.
        If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at our email: contact@first.dev.
        </Text>

        <SubTitle>7. Links to Other Websites</SubTitle>
        <Text>
        Our Website may contain links to other websites that are not operated by us. If you click on a third-party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
                        </Text>
         </TextBox>

      
      <TextBox className="term">
        <SubTitle>8. Changes to This Privacy Policy</SubTitle>
        <Text>
        We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. We will let you know via email and/or a prominent notice on our Website, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.        </Text>
        </TextBox>

      <TextBox className="term">
        <SubTitle>9. Contact Us</SubTitle>
        <Text>
        If you have any questions about this Privacy Policy, please contact us:
•	By email: contact@first.dev
        </Text>
      </TextBox>
    </TermsContainer>
  );
};

export default Policy;
