import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

const NavbarContainer = styled.nav`
  width: 100%;
  height: 80px;
  background-color: #1c374bc3;
  color: white;
  z-index: 999;
  position: fixed;
  top: 0;
  transition: background-color 0.3s;

  
  @media (max-width: 450px){
    height: 60px;
  }
`;

const NavbarInner = styled.div`
  width: 100%;
  margin: 10px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  
  @media (max-width: 450px){
    margin:0;
  }
`;

const Logo = styled.a`
  width: auto; 
  height: auto; 
  margin-left: 20px;
  display: flex;

  @media (max-width: 450px){
    margin-top: 20px;
  }
`;

const Img = styled.img`
  cursor: pointer;
  width: 130px;

  @media (max-width: 450px){
    width: 120px;
  }

  
  @media (max-width: 390px){
    width: 100px;
  }
`;

const NavLink = styled.a`
  cursor: pointer;
  text-align: center;
  font-family: 'Barlow';
  text-decoration: none;
  font-size: 20px;
  letter-spacing:2px;
  font-weight: ${props => (props.iscontact ? 700 : 400)};
  margin: 0 25px; 
  color: ${props => (props.iscontact ? '#ffc912' : '#ffffff')};
  scroll-behavior: smooth;


  &:hover {
    color: ${props => (props.iscontact ? '#ffffff' : '#ffc912')};
  }

  &.active {
    color: #FFC912; 
    @media (max-width: 1299px){
      background-color:#1A3648; 
    }
  }

  @media (max-width: 1299px){
    padding:20px 0;
    margin: 0;
    text-align: left;
  }
`;

const MenuIcon = styled.img`
  display: none;
  cursor: pointer;

  @media (min-width: 1300px){
    display:none;
  }

  @media (max-width: 1299px){
    display: block;
    margin-right: 20px;
  }
`;

const OpenNavLinks = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; 
  text-align: center;
  margin-top: 20px;
  flex-wrap: wrap;
  position: relative;
 

  @media (max-width: 1299px){
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 0;
    background-color: #152a39;
    position: absolute;
    width: 100%;
    top: 50px;
    z-index: 1001;
    margin-right: auto;
    margin-left:auto;
    border-top:1px solid gray;
  }

  @media (max-width: 480px){
    font-size: 1rem;
    margin-top: 15px;
    top: 45px;
  }
`;


const Navbar = () => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [activeSection, setActiveSection] = useState(null); 
  const sectionRefs = {
    about: useRef(null),
    trading: useRef(null),
    sports: useRef(null),
    core: useRef(null),
    offering: useRef(null),
    contact: useRef(null),
  };

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };


  const handleNavLinkClick = (sectionId) => {
    setActiveSection(sectionId);
    if (window.innerWidth <= 1299) {
      setMenuVisible(!menuVisible);
    }
  };
  



 // Scroll event handler
 const handleScroll = () => {
  const scrollY = window.scrollY;

  // Calculate the offsets of each section
  const sectionOffsets = {
    home:0,
    about: document.getElementById('about-us')?.offsetTop - 50 || 0,
    trading: document.getElementById('trading')?.offsetTop - 50 || 0,
    sports: document.getElementById('sports')?.offsetTop - 50 || 0,
    core:document.getElementById('core')?.offsetTop - 50 || 0,
    offering: document.getElementById('offering')?.offsetTop - 50 || 0,
    contact: document.getElementById('contact')?.offsetTop - 50 || 0,
  };

  // Find the section that is currently in view
  let foundActiveSection = false;
  for (const sectionId in sectionOffsets) {
    if (scrollY >= sectionOffsets[sectionId]) {
      setActiveSection(sectionId);
      foundActiveSection = true;
    }
  }

  // If no section is in the viewport, set a default active section (e.g., 'home')
  if (!foundActiveSection) {
    setActiveSection('home');
  }
};

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1300) {
        setMenuVisible(true);
      } else {
        setMenuVisible(false);
      }
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <NavbarContainer>
      <NavbarInner>
        <Logo href="/">
          <Img src="/img/First.svg" />
        </Logo>

        {!menuVisible && (
          <OpenNavLinks>

          {window.innerWidth <= 1299 ? (
          <NavLink href="#contact" iscontact className={activeSection === 'contact' ? 'active' : ''}
            onClick={() => handleNavLinkClick('contact')}
            iscontact="true"
            style={{ fontSize:'18px',border: '1px solid #ffc912',textAlign:'center',marginRight:'30px',padding:'15px 20%',marginTop:'50px',marginBottom:'50px'}}
          >
            CONTACT US
          </NavLink>
          ):null}

          {/* about  */}
          {window.innerWidth <= 1299 ? 
          <NavLink href="#about-us" className={activeSection === 'about' ? 'active' : ''}
            onClick={() => handleNavLinkClick('about')}
            style={{width:'100%',borderTop: '1px solid gray',paddingLeft:'30px'}}
          >
            About us
          </NavLink>
          :
          <NavLink href="#about-us" className={activeSection === 'about' ? 'active' : ''}
            onClick={() => handleNavLinkClick('about')}
          >
            ABOUT US
          </NavLink>
          }

            {/* trading  */}
          {window.innerWidth <= 1299 ? 
          <NavLink href="#trading" className={activeSection === 'trading' ? 'active' : ''}
            onClick={() => handleNavLinkClick('trading')}
            style={{width:'100%',borderTop: '1px solid gray',paddingLeft:'30px'}}
          >
           Trading
          </NavLink>
          :
          <NavLink href="#trading" className={activeSection === 'trading' ? 'active' : ''}
            onClick={() => handleNavLinkClick('trading')}
          >
            TRADING
          </NavLink>
          }

          {/* sports  */}
          {window.innerWidth <= 1299 ? 
          <NavLink href="#sports" className={activeSection === 'sports' ? 'active' : ''}
            onClick={() => handleNavLinkClick('sports')}
            style={{width:'100%',borderTop: '1px solid gray',paddingLeft:'30px'}}
          >
            Sportsbook
          </NavLink>
          :
          <NavLink href="#sports" className={activeSection === 'sports' ? 'active' : ''}
            onClick={() => handleNavLinkClick('sports')}
          >
            SPORTSBOOK
          </NavLink>
          }


          {/* CORE  */}
          {window.innerWidth <= 1299 ? 
          <NavLink href="#core" className={activeSection === 'core' ? 'active' : ''}
            onClick={() => handleNavLinkClick('core')}
            style={{width:'100%',borderTop: '1px solid gray',paddingLeft:'30px'}}
          >
            Core
          </NavLink>
          :
          <NavLink href="#core" className={activeSection === 'core' ? 'active' : ''}
            onClick={() => handleNavLinkClick('core')}
          >
            CORE
          </NavLink>
          }

           {/* offering  */}
           {window.innerWidth <= 1299 ? 
          <NavLink href="#offering" className={activeSection === 'offering' ? 'active' : ''}
            onClick={() => handleNavLinkClick('offering')}
            style={{width:'100%',borderTop: '1px solid gray',borderBottom: '1px solid gray',paddingLeft:'30px'}}
          >
            Our offering
          </NavLink>
          :
          <NavLink href="#offering" className={activeSection === 'offering' ? 'active' : ''}
            onClick={() => handleNavLinkClick('offering')}
          >
             OUR OFFERING 
          </NavLink>
          }


          {window.innerWidth > 1299 ? (
          <NavLink href="#contact" iscontact className={activeSection === 'contact' ? 'active' : ''}
            onClick={() => handleNavLinkClick('contact')}
            iscontact="true"
          >
            CONTACT US
          </NavLink>
          ):null}

          </OpenNavLinks>
        )}

        {window.innerWidth <= 1299 ? (
          <NavbarInner  style={{
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'flex-end',
                  marginTop:'15px'
                }}>
            <NavLink
                href="#contact"
                iscontact
                className={activeSection === 'contact' ? 'active' : ''}
                onClick={() => handleNavLinkClick('contact')}
                iscontact="true"
                style={{
                  padding: '5px',
                  border: '2px solid #ffc912',
                  fontSize: "0.8rem",
                  marginRight:'10px'
                }}
              >
                CONTACT
              </NavLink>
              
                <MenuIcon
                      onClick={toggleMenu}
                      src={!menuVisible ? '/img/Xclose.svg' : '/img/menu.svg'} 
                    />
          </NavbarInner>
        ) : null}

   
      </NavbarInner>
    </NavbarContainer>
  );
};

export default Navbar;

