import React, { useState ,forwardRef} from 'react'; 
import styled from 'styled-components';
import Title from './Title';

const Section = styled.div`
  height: 100vh;
  overflow-x: hidden;
  background: linear-gradient(to bottom, black, #03161D);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  position: relative;
  /* background: red; */
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 75%;
  overflow: hidden;
  /* background: purple; */
  position: relative;


  @media (max-width: 800px){
    margin-top: 20px;
    height: 600px;
  }
`;

const CarouselImage = styled.img`
  width: 80em; 
  height: 40em; 
  position: relative;
  display: ${props => (props.visible ? 'block' : 'none')};

  @media (max-width: 1600px){
    height: 30em; 
    margin-top: 2%;
  }

   @media (max-width: 1400px){
      width: 1100px;
  }

  @media (max-width: 1300px){
      width: 1000px;
  }

  @media (max-width: 1200px){
      width: 900px;
  }

  @media (max-width: 1100px){
    width: 800px;
  }

  @media (max-width: 900px){
    width: 700px;
  }

  @media (max-width: 800px){
    width: 350px;
    margin-top: -150px;
   
  }

  @media (max-width: 440px){
    width: 320px;
    margin-top: -180px;
  }
 
`;

const Indicators = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  margin-top:2%;

  @media (max-width: 1600px){
    margin-bottom:30px;
  }

  @media (max-width: 440px){
    margin-top:-60px;
  }

`;

const Indicator = styled.div`
  width: 40px; /* Adjust the indicator width */
  height: 3px; 
  background-color: ${props => (props.active ? '#8AA4B5' : '#8aa4b589')};
  margin: 0 10px;
  cursor: pointer;
`;

const ImageButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  bottom: 40px; 
  width: 100%;
  /* background: #ffc0cba2; */
  position: absolute; 
  z-index: 777;

  @media (max-width: 1600px){
    bottom: 50px;
  }

  @media (max-width: 1100px){
    bottom: 80px;
  }

  @media (max-width: 900px){
    bottom: 100px;
  }

  @media (max-width: 800px){
    display: none;
  }
  
`;

const ImageButton = styled.img`
  width: 50px;
  height: 50px;
  padding: 0 20px;
  cursor: pointer;

  @media (max-width: 1200px){
    width: 40px;
  }

  @media (max-width: 780px){
    padding: 0 150px;
    width: 40px;
    height: 40px;
  }

  @media (max-width: 800px){
    padding: 0 120px;
  }

  @media (max-width: 440px){
    padding: 0 110px;
  }
  
`;


const CarouselImageTitle = styled.p`
 color: #ffffff;
  font-size: 3em;
  position: absolute;
  font-family: 'Barlow';
  font-weight: bold;
  top: 200px;
  margin-left: 7%; /* Adjust this for the desired position */
  width: 200px;
  text-transform: uppercase;
  letter-spacing: 2.96px;
  text-align: left;
  display: ${props => (props.visible ? 'block' : 'none')};

  @media (max-width: 1600px){
    font-size: 2.5em;
    margin-left: 17%;
    top: 130px;
  }

  @media (max-width: 1400px){
    margin-left: 12%;
  }

  @media (max-width: 1300px){
    margin-left: 10%;
  }

  @media (max-width: 800px){
    margin-left: 10px; 
    top: 310px;
    font-size: 2.4em;
  }

  @media (max-width: 440px){
    top: 280px;
  }

`;

const CarouselImageSub = styled.p`
 color: #ffffff;
  font-size: 1.3em;
  position: absolute;
  font-family: 'Roboto';
  font-weight: 300;
  z-index: 6;
  top: 350px; /* Adjust this for the desired position */
  margin-left: 7%;/* Adjust this for the desired position */
  width: 490px;
  line-height: 1.5;
  text-align: left;
  display: ${props => (props.visible ? 'block' : 'none')};

  @media (max-width: 1600px){
    font-size: 1em;
    width: 400px;
    margin-left: 17%;
    top: 250px;
  } 

  @media (max-width: 1400px){
    margin-left: 12%;
  }

  @media (max-width: 1300px){
    margin-left: 10%;
  }

  @media (max-width: 800px){
    margin-left: auto;
    margin-right :auto;
    width: 350px;
    top: 440px;
    /* background: pink; */
  }

  @media (max-width: 440px){
    font-size:1em;
    width: 320px;
    top: 405px;
  }

  @media (max-width: 390px){
    font-size:1em;
  }
`;






const Carousel =  forwardRef((props, ref) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [touchStartX, setTouchStartX] = useState(null);

    const images = [
      '/img/soccer.svg',
      '/img/basketball.svg',
      '/img/cod.svg',
    ];
  
    const imagesMobile = [
      '/img/soccerM.svg',
      '/img/basketballM.svg',
      '/img/codM.svg',
    ];
  
    const textTitle = [
      'SPORTS DATA',
      'Promotional Tools',
      'Retention Tools',
    ];
  
    const subTitles = [
      'Live Stream across all leagues, sports and matches. Most sophisticated in-house Match Tracker in the industry, providing specific play by play incidents',
      'A strong package of marketing tools including: Multi banner - Pop-ups - Featured Leagues',
      'Exclusive tools to reward and retain good players Configurable early payout system',
    ];
  
    const handleNext = () => {
      setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    };
  
    const handlePrev = () => {
      setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    };
    
    const handleIndicatorClick = index => {
      setCurrentIndex(index);
    };
  
    const handleImageButtonClick = newIndex => {
      setCurrentIndex(newIndex);
    };

    const handleTouchStart = (event) => {
      setTouchStartX(event.touches[0].clientX);
    };

    const handleTouchMove = (event) => {
      if (touchStartX !== null) {
        const deltaX = event.touches[0].clientX - touchStartX;
        const sensitivity = 50;
  
        if (deltaX > sensitivity) {
          // Swipe right
          handleImageButtonClick((currentIndex - 1 + images.length) % images.length);
          setTouchStartX(null);
        } else if (deltaX < -sensitivity) {
          // Swipe left
          handleImageButtonClick((currentIndex + 1) % images.length);
          setTouchStartX(null);
        }
      }
    };
  
    const prevIndex = (currentIndex - 1 + images.length) % images.length;
    const nextIndex = (currentIndex + 1) % images.length;
  
    const translateXValue = `-${currentIndex * 0}%`;

  return (
    <Section id="offering" >
      <Title content="OUR OFFERING"/>
      <ContentContainer>
      {(window.innerWidth <= 800 ? imagesMobile : images).map((image, index) => (
        <div>
          <CarouselImage
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            style={{ transform: `translateX(${translateXValue})` }}
            key={index}
            src={image}
            alt={`Image ${index + 1}`}
            visible={currentIndex === index}
          />
     
          <CarouselImageTitle visible={currentIndex === index}>{textTitle[index]}</CarouselImageTitle>
          <CarouselImageSub visible={currentIndex === index}>{subTitles[index]}</CarouselImageSub>
        </div>
      ))}
        <ImageButtonContainer>
              <ImageButton
                src="/img/left.svg"
                alt="Previous"
                onClick={() => handleImageButtonClick(prevIndex)}
              />
              <ImageButton
                src="/img/right.svg"
                alt="Next"
                onClick={() => handleImageButtonClick(nextIndex)}
              />
          </ImageButtonContainer>

      </ContentContainer>

      <Indicators>
        {images.map((_, index) => (
          <Indicator
            key={index}
            active={currentIndex === index}
            onClick={() => handleIndicatorClick(index)}
          />
        ))}
      </Indicators>
    </Section>
  );
});

export default Carousel;