import React ,{forwardRef} from 'react';
import styled from 'styled-components';
import Title from './Title';

const Section = styled.div`
  height: 100vh;
  background: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  overflow-x: hidden;
`;

const ContentContainer = styled.div`
 position: relative; 
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 90%;
  margin-top: 3%;

  @media (max-width: 800px) { 
    height: 75%;
    margin-top:0px;
    padding:25px 0;
  }


`;

const Img =styled.img`
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: red; */

  @media (max-width: 1100px) { 
    width: 35%;
  }

  @media (max-width: 800px) { 
    width: 260px;
  }

`;


const Text = styled.div`
  font-size: 22px;
  font-family: 'Barlow';
  letter-spacing: 0.4px;
  color: #EDF2F6;
  text-align: left;
  z-index: 665;
`;

const TextTopLeft = styled(Text)`
  width: 250px; /* Adjust the width as needed */
  /* background: green; */
  margin-left: 3%;
  font-size:2.5vh;

  @media (max-width: 1728px) and (max-height: 1117px){ 
    width: 330px;
  }

  @media (max-width: 1727px) { 
    width: 230px;
  }

  @media (max-width: 1600px) { 
    width: 200px;
  }

  @media (max-width: 800px) { 
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  @media (max-width: 500px) { 
    width: 230px;
    font-size:1.2em;
  }
`;

const TextTopRight = styled(Text)`
  width: 280px; /* Adjust the width as needed */
  margin-right: 3%;
  font-size:2.5vh;
  /* background: green; */

 @media (max-width: 1728px) and (max-height: 1117px){ 
    width: 330px;
  }

  @media (max-width: 1727px) { 
    width: 280px;
  }

  @media (max-width: 1600px) { 
    width: 220px;
  }

  @media (max-width: 800px) { 
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  @media (max-width: 500px) { 
    font-size:1.2em;
    width: 230px;
  }
`;

const TextBottomRight = styled(Text)`
  width: 150px; /* Adjust the width as needed */
  margin-right: 12%;
  font-size:2.5vh;
  /* background: green; */

  @media (max-width: 1728px) and (max-height: 1117px){ 
    width: 250px;
    margin-right: 8%;
  }

  @media (max-width: 1727px) { 
    margin-right: 5%;
  }

  @media (max-width: 1600px) { 
    margin-right: 10%;
    width: 120px;
  }

  @media (max-width: 800px) { 
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  @media (max-width: 500px) { 
    font-size:1.2em;
    width: 200px;
  }
`;

const TextBottomLeft = styled(Text)`  
  width: 280px; /* Adjust the width as needed */
  margin-left: 2%;
  font-size:2.5vh;
  /* background: green; */

  @media (max-width: 1728px) and (max-height: 1117px){ 
    width: 330px;
  }

  @media (max-width: 1727px) { 
    width: 270px;
  }

  @media (max-width: 1600px) { 
    width: 220px;
  }

  @media (max-width: 800px) { 
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  @media (max-width: 500px) { 
    font-size:1.2em;
    width: 230px;
  }
`;



const TextContainer = styled.div`
  /* background: #80008066; */
  width: 85%;
  height: 90%;
  position: absolute; 
  z-index: 555;

  @media (max-width: 1600px) { 
    width: 90%;
  }

  @media (max-width: 800px) { 
    width: 100%;
  }
`;

const TextTopContainer = styled.div`
  /* background: #c4811566; */
  width: 100%;
  height: 120px;
  position: relative;
  z-index: 555;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; 
  top: 3%;
  
  @media (max-width: 1728px) and (max-height: 1117px){ 
    top: 13%;
  }

  @media (max-width: 1727px) { 
    top: 0;
  }

  @media (max-width: 800px) { 
    flex-direction: column; 
    height: 200px;
    top: 0;
  }


  @media (max-width: 390px) { 
    height: 180px;
  }

`;


const TextBottomContainer = styled.div`
  /* background: #c4811566;  */
  width: 100%;
  height: 120px;
  top: 48%;
  position: relative;
  z-index: 555;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; 

  @media (max-width: 1728px) and (max-height: 1117px){ 
    top: 47%;
  }

  
  @media (max-width: 1727px) { 
    top: 38%;
  }

  @media (max-width: 800px) { 
    flex-direction: column; 
    height: 180px;
    top: 40%;
  }

  @media (max-width: 390px) { 
    height: 150px;
  }

`;


const Core =  forwardRef((props, ref) => {


  return (
    <Section id="core" >
      <Title content="CORE"/>
      <ContentContainer>
        <Img src="/img/Enginenew.svg"/>
        <TextContainer>
          <TextTopContainer>
            <TextTopLeft>Highest uptime in the industry tracked during the World Cup 2022</TextTopLeft>
            <TextTopRight>Creation of fully dedicated Sportsbook instance in less than 5 minutes</TextTopRight>
          </TextTopContainer>
         

           <TextBottomContainer>
                <TextBottomLeft>Fastest settlement in the B2B industry, settling over 3 million bets per second</TextBottomLeft>
                <TextBottomRight>24/7 Instant Live Support</TextBottomRight>
           </TextBottomContainer>
        </TextContainer>
      </ContentContainer>
    </Section>
  );
});

export default Core;