import React from 'react'
import Hero from './components/Hero';
import Who from './components/Who';
import Works from './components/Works';
import Contact from './components/Contact';
import About from './components/About';
import Trading from './components/Trading';
import Paralax from './components/Paralax';
import Rev from './components/Revpara';
import Offices from './components/Offices';
import Blue from './components/Blue';
import Core from './components/Core';
import Sportsbook from './components/Sportsbook';
import Footer from './components/Footer';
import Grid from './components/Grid';
import Navbar from './components/Navbar';
import Carousel from './components/Carousel';
import White from './components/White';
import Terms from './components/Terms';
import Policy from './components/Policy';
import styled from 'styled-components';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

const Container = styled.div`
  color: white;
  background: #0000003e;
`;

export default function App() {

  return (
    <Router>
    <Navbar /> 
    <Container>
    <Routes>
       <Route path="/" element={
              <>
                <Hero />
                <Who content="ABOUT" />
                <Works />
                <About />
                <Trading />
                <Offices />
                <Blue />
                <Paralax />
                <White/>
                <Rev />
                <Sportsbook />
                <Core/>
                <Carousel />
                <Contact  />
              </>
            }
          />

        <Route path="/terms" element={
              <>
                    <Terms/>
              </>
            }
          />

        <Route path="/policy" element={
              <>
                    <Policy/>
              </>
            }
          />
      </Routes>
    </Container>
    <Footer /> 
    </Router>
  );
}

