import React from 'react';
import styled from 'styled-components';

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top:80px;
`;

const TitleText = styled.h1`
  display: flex;
  font-size: 26px;
  letter-spacing: 2.6px;
  font-family: 'Barlow', sans-serif;
  font-weight: 700;
  justify-content: center;
  align-items: center; 
  transform:scaleX(1);
  color: ${props => (props.isTeam ? '#265A7C' : props.isSport ? '#639CA7': props.isCon ? "#ffc912" : '#ffff')}; 
`;

function Title(props) {
  return (
    <TitleContainer>
       <TitleText isCon={props.isCon} isSport={props.isSport} isTeam={props.isTeam}>{props.content}</TitleText>
    </TitleContainer>
  )
}

export default Title
