import React from 'react';
import styled from 'styled-components';


const GridItem = styled.div`
  width: 100%;
  height: 100%; 
  background-color: #85c8ff53;
`;


const GridContainer = styled.div`
  position: fixed;
  height: 100%;
  pointer-events: none;
  z-index: 999;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr; /* Set to 1fr to ensure one row */
  gap: 27px;

  @media (max-width: 391px) {
    width: 82%;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr; /* Set to 1fr to ensure one row */
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  @media (min-width: 391px) and (max-width: 1244px) {
    width: 82%;
    grid-template-columns: repeat(4, 1fr);
    justify-content: center; /* Center columns horizontally */
    align-items: center; /* Center columns vertically */
    gap: 10px;
  }

  @media (min-width: 1245px) {
    position: sticky;
    top: 0; /* Stick to the top of the viewport */
    height: 100vh; /* Allow height to be determined by content */
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin: 0 120px;
    gap: 27px;
    grid-template-columns: repeat(12, 1fr);
  }
`;




const Grid = () => {
  return (
    <GridContainer>
      <GridItem></GridItem>
      <GridItem></GridItem>
      <GridItem></GridItem>
      <GridItem></GridItem>
      <GridItem></GridItem>
      <GridItem></GridItem>
      <GridItem></GridItem>
      <GridItem></GridItem>
      <GridItem></GridItem>
      <GridItem></GridItem>
      <GridItem></GridItem>
      <GridItem></GridItem>
    </GridContainer>
  );
};

export default Grid;
