import React from 'react';
import styled from 'styled-components';
import Title from './Title';
import video from '../Medal_1.mp4';

const Section = styled.div`
   height: 100vh;
   width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #000000;

  
`;

const Container = styled.div`
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow: hidden;
  margin-top: 5%;
  /* background: red; */

  @media (max-width: 1100px) {
    flex-direction: column; /* Stack content in a column for screens at most 768px wide */
  }
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: purple; */
  margin-left: 5%;
  /* overflow: hidden; */

 

  @media (max-width: 1100px) {
    justify-content: center; 
    align-items: center;
    margin-right: 5%;
    flex: 2;
  }


  @media (max-width: 420px) {
    justify-content: center; 
    align-items: center;
    margin-top: 13vh;
    flex: 1;
  }
`;

const Desc = styled.h2`
  width: 19em;
  height: auto;
  font-size: 1.67em;
  letter-spacing: 0.02em;
  text-align: center;
  font-weight: 300;
  font-family: 'Barlow';
  line-height: 1.6;
  margin-left: 5em;
  margin-right: auto;
  margin-top: -80px;


  @media (max-width: 1380px) {
    width: 18em;
    margin-right: auto;
    margin-left: auto;
    /* background: green; */
  }

  @media (max-width: 1200px) {
    width: 18em;
    margin-right: auto;
    margin-left: auto;
    /* background: blue; */
  }

  @media (max-width: 1100px) {
    width: 18em;
    margin-right: auto; 
    margin-left: auto;
    margin-top: -5vh;
    /* background: pink; */
  }

  @media (max-width: 560px) {
    width: 18em;
    font-size: 1.47em;
    margin-right: auto; 
    margin-left: auto;
    /* margin-top: -5vh; */
    /* background: yellow; */
  }

  @media (max-width: 480px) {
    width: 18em;
    font-size: 1.3em;
    margin-right: auto; 
    margin-left: auto;
    /* background: purple; */
  }

  @media (max-width: 420px) {
    width: 19em;
    font-size: 1.2em;
    margin-right: auto; 
    margin-left: auto;
    /* background: green; */
  }


`;

const Right = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start; /* Horizontally align content to the left */
  align-items: center;
  /* background: #cfa351; */
  margin-right: 5%;



  @media (max-width: 1100px) {
    flex: 1;
    justify-content: center; /* Center content when stacked */
    align-items: center;
    margin-left: 5%;
  }

  @media (max-width: 420px) {
    margin-right: auto; 
    margin-left: 5%;
    margin-right: 5%;
  }
`;


const Video = styled.video`
  width: 330px;
  height: auto;
  max-height: 50vh;
  margin-left: auto;
  margin-right: 10em;

  @media (max-width: 1380px) {
    max-height: 50vh; 
    margin-right: auto;
    margin-left: auto;
  }

  @media (max-width: 1200px) {
    max-height: 50vh; 
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 1100px) {
    max-height: 50vh; 
    margin-left: auto;
    margin-right: auto;
    margin-top: 10vh;
  }

  @media (max-width: 420px) {
    max-height: 40vh; 
    margin-left: auto;
    margin-right: auto;
    margin-top: 5vh;
  }

`;

const Works = () => {
  return (
    <Section id="works">
      <Container>
        <Left>
          <Video autoPlay loop muted playsInline={true} controls={false}>
            <source src={video} type="video/mp4" />
          </Video>
        </Left>

        <Right>
          <Desc>Our technology team led the most successful B2B Sportsbook software
           company and created a Sportsbook platform that is running on the largest 
           sports betting operators in the world</Desc>
        </Right>
      </Container>
    </Section>
  );
};

export default Works;